import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[tooltip]',
  standalone: true,
})
export class TooltipDirective {
  @Input('tooltip')
  public title: string = '';
  public isEllipsis: boolean = false;

  constructor(private readonly el: ElementRef) {}

  @HostListener('mouseover')
  public onMouseOver(): void {
    this.isEllipsis =
      this.el.nativeElement.scrollHeight > this.el.nativeElement.clientHeight ||
      this.el.nativeElement.scrollWidth > this.el.nativeElement.clientWidth;

    this.el.nativeElement.title = this.isEllipsis && this.title ? this.title : '';
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.isEllipsis = false;
    this.el.nativeElement.title = '';
  }
}
